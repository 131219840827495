import { gql } from '@apollo/client'
import { toNumber } from 'lodash-es'
import { avg } from '../common/utils'
import Municipality from '../data-models/municipality'
import School from '../data-models/school'
import { BaseUser } from '../data-models/user'
import { graphQlClient } from './graphql'
import { RoleService } from './roleService'

export const SchoolService = {
  GET_SCHOOL: gql`
    query School($schoolId: Int!) {
      school(id: $schoolId) {
        name
        municipality {
          name
          county
        }
        contact {
          name
          email
          phone
          address
          zip
          city
        }
      }
    }
  `,

  UPDATE_SCHOOL: gql`
    mutation School(
      $schoolId: Int!
      $name: String!
      $email: String!
      $phone: String!
      $address: String!
      $zip: String!
      $city: String!
    ) {
      updateSchoolContact(
        schoolId: $schoolId
        contact: {
          name: $name
          email: $email
          phone: $phone
          address: $address
          zip: $zip
          city: $city
        }
      )
    }
  `,

  GET_MUNICIPALITY_SCHOOLS_BASIC: gql`
    query Schools($municipalityId: Int!) {
      schools(municipalityId: $municipalityId) {
        id
        name
        has9Grades
        contact {
          email
          phone
        }
      }
    }
  `,

  GET_AVAILABLE_SCHOOLS_BASIC: gql`
    query SchoolsByIds($schoolIds: [Int!]) {
      schoolsByIds(ids: $schoolIds) {
        id
        name
        has9Grades
        contact {
          email
          phone
        }
      }
    }
  `,

  GET_SCHOOLS_STATISTICS: gql`
    query SchoolStatistics($semesters: [String!], $schoolIds: [Int!]) {
      schoolStatistics(semesters: $semesters, schoolIds: $schoolIds) {
        semester
        school {
          id
          name
        }
        values {
          name
          value
        }
      }
    }
  `,

  GET_SCHOOL_SUMMARIES: gql`
    query SchoolSummaries($municipalityId: Int!) {
      schoolSummaries(municipalityId: $municipalityId) {
        school {
          id
          name
        }
        absenceTotalPercentage
        preschoolMonths
        preschoolHoursPerWeek
        foreignBackground
        english
        swedish
        mathematics
        semester
        eligibility
        averageFailedCourses
        parentsWithHigherEducation
      }
    }
  `,

  GET_SCHOOLS_SEMESTER_SUMMARY: gql`
    query SchoolsSemesterSummary($municipalityId: Int!) {
      schoolsSemesterSummary(municipalityId: $municipalityId) {
        semester
        municipalityId
        foreignVsPreschoolHoursIntercept
        foreignVsPreschoolHoursSlope
        parentsEducationVsPreschoolHoursIntercept
        parentsEducationVsPreschoolHoursSlope
      }
    }
  `,

  GET_SCHOOL_SUMMARY_GBP: gql`
    query SchoolSummariesGbp($municipalityId: Int!) {
      schoolSummariesGbp(municipalityId: $municipalityId) {
        school {
          id
          name
        }
        semester
        gbpNewlyArrived
        gbpNotNewlyArrived
        foreignBackground
        parentsHigherEducation
        absence
        studentsPerTeacher
        preschoolHoursWeek
        preschoolMonths
      }
    }
  `,

  GET_DASHBOARD_DATA: gql`
    query DashboardGridSummary(
      $municipalityId: Int!
      $semester: String!
      $schoolIds: [Int!]
    ) {
      dashboardGridSummary(
        municipalityId: $municipalityId
        semester: $semester
        schoolIds: $schoolIds
      ) {
        school {
          id
          name
        }
        semester
        results
        schoolEvalutionScore
        schoolHealthMetrics
        socioEconomics
      }
    }
  `,

  GET_BACKGROUND_GBP_DATA: gql`
    query FeaturesImpactGbpBeesworms($municipalityId: Int!) {
      featuresImpactGbpBeesworms(municipalityId: $municipalityId) {
        foreignBackground
        foreignBackgroundImpactGbp
        parentsHigherEducation
        parentsHigherEducationImpactGbp
        absencePercentage
        absencePercentageImpactGbp
        studentsPerTeacher
        studentsPerTeacherImpactGbp
        schoolTempenStudents
        schoolTempenStudentsImpactGbp
        teacherSickLeave
        teacherSickLeaveImpactGbp
        teachersSpecialEducation
        teachersSpecialEducationImpactGbp
        staffTurnover
        staffTurnoverImpactGbp
        teacherQuestionnaire
        teacherQuestionnaireImpactGbp
      }
    }
  `,

  GET_BACKGROUND_ELIG_DATA: gql`
    query FeaturesImpactEligBeesworms($municipalityId: Int!) {
      featuresImpactEligBeesworms(municipalityId: $municipalityId) {
        foreignBackground
        foreignBackgroundImpactElig
        parentsHigherEducation
        parentsHigherEducationImpactElig
        absencePercentage
        absencePercentageImpactElig
        studentsPerTeacher
        studentsPerTeacherImpactElig
        schoolTempenStudents
        schoolTempenStudentsImpactElig
        teacherSickLeave
        teacherSickLeaveImpactElig
        teachersSpecialEducation
        teachersSpecialEducationImpactElig
        staffTurnover
        staffTurnoverImpactElig
        teacherQuestionnaire
        teacherQuestionnaireImpactElig
      }
    }
  `,

  GET_SCHOOL_SUMMARY_GBP_LINES: gql`
    query SchoolSummariesGbpLines($municipalityId: Int!) {
      schoolSummariesGbpLines(municipalityId: $municipalityId) {
        gbpNewlyArrivedVsForeignBackgroundIntercept
        gbpNewlyArrivedVsForeignBackgroundSlope
        gbpNewlyArrivedVsParentsHigherEducationIntercept
        gbpNewlyArrivedVsParentsHigherEducationSlope
        gbpNewlyArrivedVsAbsenceIntercept
        gbpNewlyArrivedVsAbsenceSlope
        gbpNewlyArrivedVsStudentsPerTeacherIntercept
        gbpNewlyArrivedVsStudentsPerTeacherSlope

        gbpNotNewlyArrivedVsForeignBackgroundIntercept
        gbpNotNewlyArrivedVsForeignBackgroundSlope
        gbpNotNewlyArrivedVsParentsHigherEducationIntercept
        gbpNotNewlyArrivedVsParentsHigherEducationSlope
        gbpNotNewlyArrivedVsAbsenceIntercept
        gbpNotNewlyArrivedVsAbsenceSlope
        gbpNotNewlyArrivedVsStudentsPerTeacherIntercept
        gbpNotNewlyArrivedVsStudentsPerTeacherSlope
        gbpNotNewlyArrivedVsPreschoolHoursWeekIntercept
        gbpNotNewlyArrivedVsPreschoolHoursWeekSlope
        gbpNotNewlyArrivedVsPreschoolMonthsIntercept
        gbpNotNewlyArrivedVsPreschoolMonthsSlope
      }
    }
  `,

  getSchoolsForCurrentUser: (user: BaseUser, municipality: Municipality) => {
    let query
    let variables: any
    let resultKey: string

    if (RoleService.hasMunicipalitySchoolsSubset(user)) {
      query = SchoolService.GET_AVAILABLE_SCHOOLS_BASIC
      variables = { schoolIds: user.schoolIds }
      resultKey = 'schoolsByIds'
    } else {
      query = SchoolService.GET_MUNICIPALITY_SCHOOLS_BASIC
      variables = {
        municipalityId: municipality.code,
      }
      resultKey = 'schools'
    }

    return graphQlClient
      .query({
        query,
        variables,
      })
      .then(({ data }) => {
        const arrayForSort = [...data[resultKey]]
        return (arrayForSort.sort((a, b) => a.name!.localeCompare(b.name!)) ||
          []) as Partial<School>[]
      })
  },

  getSchoolStatistic: (school: Partial<School>, statistic: string) =>
    (school.statistics || [])
      .flatMap(s => s.values)
      .filter(s => s.name === statistic)
      .map(s => toNumber(s.value)),

  getSchoolStatisticAvg: (schools: Partial<School>[], statistic: string) =>
    avg(
      schools.flatMap(s => SchoolService.getSchoolStatistic(s, statistic)),
      2
    ),
}
