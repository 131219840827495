import React, { useState, useContext, createContext } from 'react'
import School from '../data-models/school'

const SchoolContext = createContext<{
  schools: Partial<School>[]
  setSchools: (schools: Partial<School>[]) => void
} | null>(null)

function SchoolProvider(props: { children: React.ReactNode }) {
  const [schools, setSchools] = useState([] as Partial<School>[])
  const value = {
    schools,
    setSchools,
  }

  return (
    <SchoolContext.Provider value={value}>
      {props.children}
    </SchoolContext.Provider>
  )
}

function useSchool() {
  const ctx = useContext(SchoolContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { SchoolProvider, useSchool }
