import Cookies from 'js-cookie'
import { isLocalhostEnv } from './environment'

const config = isLocalhostEnv()
  ? {
      domain: 'localhost',
    }
  : {
      domain: 'skolanalys.se',
      secure: true,
    }

export const cookies = Cookies.withAttributes(config)
