import { UseToastOptions } from '@chakra-ui/toast'
import { ValueIteratee } from 'lodash'
import {
  partialRight,
  sum,
  toLower,
  trim,
  round,
  groupBy,
  values,
  mean,
  orderBy,
} from 'lodash-es'
import { BackgroundFactorIndicatorLabel } from 'services/backgroundService'
import { SchoolSemesterType } from 'services/filterMappings'

export const skolanalysModules = {
  grundskola: 'Grundskola',
  forskola: 'Forskola',
  gymnasium: 'Gymnasium',
}

export const getInitials = (name: string) => {
  const first = (str: string) => str[0]
  return name.split(' ').map(first).join('')
}

export const formatDate = (date: Date | undefined) => {
  if (!date) {
    return ''
  }
  return date.toLocaleString('sv-SE', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
  })
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// *Math operations
export const round1 = partialRight(round, 1) as any // fix 'any' to something more type-correct https://github.com/DefinitelyTyped/DefinitelyTyped/issues/50418
export const round2 = partialRight(round, 2) as any
export const round3 = partialRight(round, 3) as any
export const round4 = partialRight(round, 4) as any

export const avgInteger = (arr: number[]) =>
  arr.length > 0 ? Math.round(sum(arr) / arr.length) : 0
export const avg = (arr: number[], decimals?: number) => {
  if (arr.length === 0) return 0
  return decimals
    ? +(sum(arr) / arr.length).toFixed(decimals)
    : sum(arr) / arr.length
}
export const avg3 = (arr: number[]) => round3(avg(arr))
export const avg2 = (arr: number[]) => round2(avg(arr))
export const avg1 = (arr: number[]) => round1(avg(arr))
// *Math operations

export const formatSemesters = (arr: string[]): SchoolSemesterType[] =>
  [...new Set(arr)]
    .sort()
    .reverse()
    .map(x => ({
      id: x,
      label: x,
      value: parseInt(x.substring(2, 2)),
      type: x.substring(0, 2),
    }))
    .filter(x => !x.id.includes('HT'))

export const lowerTrim = (str?: string) => toLower(trim(str || ''))

export const successToast = (msg: string): UseToastOptions => ({
  status: 'success',
  title: msg,
  position: 'bottom-right',
})

export const errorToast = (msg: string): UseToastOptions => ({
  status: 'error',
  title: msg,
  position: 'bottom-right',
})

export const generateColor = () => {
  const hexValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F']
  let hex = '#'

  for (let i = 0; i < 6; i++) {
    const index = Math.floor(Math.random() * hexValues.length)
    hex += hexValues[index]
  }
  return hex
}

export const strToColour = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export type valueof<T> = T[keyof T]

export const keylessGroupBy = <T>(x: T[], fn: ValueIteratee<T>) =>
  values(groupBy(x, fn))

export const semesterExcludeAbsence = ['VT13', 'VT14', 'VT15', 'VT16']

export const semesterExcludeEvaluation = [
  'VT13',
  'VT14',
  'VT15',
  'VT16',
  'VT17',
  'VT18',
  'VT19',
  'VT20',
]

export const computeGradientColor = (
  start_color: string,
  end_color: string,
  percent: number
) => {
  // strip the leading # if it's there
  start_color = start_color.replace(/^\s*#|\s*$/g, '')
  end_color = end_color.replace(/^\s*#|\s*$/g, '')

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (start_color.length === 3) {
    start_color = start_color.replace(/(.)/g, '$1$1')
  }

  if (end_color.length === 3) {
    end_color = end_color.replace(/(.)/g, '$1$1')
  }

  // get colors
  const start_red = parseInt(start_color.substr(0, 2), 16)
  const start_green = parseInt(start_color.substr(2, 2), 16)
  const start_blue = parseInt(start_color.substr(4, 2), 16)

  const end_red = parseInt(end_color.substr(0, 2), 16)
  const end_green = parseInt(end_color.substr(2, 2), 16)
  const end_blue = parseInt(end_color.substr(4, 2), 16)

  let diff_red = ((end_red - start_red) * percent + start_red)
    .toString(16)
    .split('.')[0]
  let diff_green = ((end_green - start_green) * percent + start_green)
    .toString(16)
    .split('.')[0]
  let diff_blue = ((end_blue - start_blue) * percent + start_blue)
    .toString(16)
    .split('.')[0]

  // ensure 2 digits by color
  if (diff_red.length === 1) diff_red = '0' + diff_red
  if (diff_green.length === 1) diff_green = '0' + diff_green
  if (diff_blue.length === 1) diff_blue = '0' + diff_blue

  return '#' + diff_red + diff_green + diff_blue
}

export const camelCaseToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const orderCategoriesBasedOnMean = (
  stats?: { impact: number; weight: number; name: string }[]
): Record<number, string> => {
  const categoryNames = Object.values(BackgroundFactorIndicatorLabel)

  const categoryMeans = categoryNames.map(name => ({
    name,
    value: mean(
      stats
        ?.filter(stat => stat.name === name)
        ?.map(stat => Math.abs(stat.impact))
    ),
  }))

  const sortedCategories = orderBy(categoryMeans, ['value'], 'asc')

  let tickCounter = 2.25
  const increment = 2
  const categoryLabels = sortedCategories.reduce((acc, curr) => {
    const result = { ...acc, [tickCounter]: curr.name }
    tickCounter = tickCounter + increment
    return result
  }, {})

  return categoryLabels
}
