import React from 'react'
import FullPageSpinner from './FullPageSpinner'
import { Text } from '@chakra-ui/react'

export default function AuthLoading() {
  return (
    <FullPageSpinner>
      <Text color="white" fontSize="2xl">
        Authenticating...
      </Text>
    </FullPageSpinner>
  )
}
