import { gql } from '@apollo/client'
import { partialRight, orderBy } from 'lodash-es'
import { axiosAPIInstance } from './api'
import Municipality from '../data-models/municipality'

const orderByName = partialRight(orderBy, ['name'])

const MunicipalityService = {
  GET_MUNICIPALITY_BACKGROUND_FACTOR: gql`
    query MunicipalityBackgroundFactors($municipalityId: Int!) {
      municipalityBackgroundFactors(municipalityId: $municipalityId) {
        name
        eligibilityValue
        gBPValue
      }
    }
  `,

  getMunicipality: (id: number) =>
    axiosAPIInstance
      .get<Municipality>(`/municipalities/${id}`)
      .then(r => r.data),

  getMunicipalities: () =>
    axiosAPIInstance
      .get<Municipality[]>(`/municipalities`)
      .then(r => r.data)
      .then(orderByName),

  updateMunicipality: (municipality: Partial<Municipality>) =>
    axiosAPIInstance.put<Municipality>('/municipalities', municipality),
}

export { MunicipalityService }
