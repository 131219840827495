import { Center, HStack, Spinner, VStack } from '@chakra-ui/react'
import React from 'react'
import Logo from 'assets/images/Logo.svg'

export default function FullPageSpinner(props: { children: React.ReactNode }) {
  return (
    <Center h="full" bgColor="#277aff">
      <VStack spacing="10">
        <img src={Logo} alt="logo" />
        <HStack mt="50" alignItems="center" justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="white"
            size="xl"
            mr="5"
          />
          {props.children}
        </HStack>
      </VStack>
    </Center>
  )
}
