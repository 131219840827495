import React, { useState, useContext, createContext } from 'react'
import Municipality from '../data-models/municipality'

const MunicipalityContext = createContext<{
  municipality: Municipality
  municipalities: Municipality[]
  setMunicipality: (municipality: Municipality) => void
  setMunicipalities: (municipalities: Municipality[]) => void
} | null>(null)

function MunicipalityProvider(props: { children: React.ReactNode }) {
  const [municipality, setMunicipality] = useState({} as Municipality)
  const [municipalities, setMunicipalities] = useState([] as Municipality[])
  const value = {
    municipality,
    setMunicipality,
    municipalities,
    setMunicipalities,
  }

  return (
    <MunicipalityContext.Provider value={value}>
      {props.children}
    </MunicipalityContext.Provider>
  )
}

function useMunicipality() {
  const ctx = useContext(MunicipalityContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { MunicipalityProvider, useMunicipality }
