import React, { useContext, createContext, useState } from 'react'
import User from '../data-models/user'

const UserContext = createContext<{
  user: User
  setUser: (user: User) => void
  users: User[]
  setUsers: (users: User[]) => void
} | null>(null)

function UserProvider(props: { children: React.ReactNode }) {
  const [user, setUser] = useState({} as User)
  const [users, setUsers] = useState([] as User[])
  const value = { user, setUser, users, setUsers }

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

function useUser() {
  const ctx = useContext(UserContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { UserProvider, useUser }
