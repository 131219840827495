import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import './index.css'
import extendedTheme from './theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MunicipalityProvider } from './providers/MunicipalityProvider'
import { UserProvider } from './providers/UserProvider'
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'
import { createGraphQlClient, graphQlClient } from './services/graphql'
import { SchoolProvider } from './providers/SchoolProvider'
import { populateEnv } from 'services/environment'
import { createAxiosInstance } from 'services/api'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import enTranslation from './locales/en.json'
import svTranslation from './locales/sv.json'
const setupEnv = async () => {
  await populateEnv()
  createAxiosInstance()
  createGraphQlClient()
}
i18n.init({
  interpolation: { escapeValue: false },
  lng: 'se', // Default language
  resources: {
    en: {
      translation: enTranslation,
    },
    se: {
      translation: svTranslation,
    },
  },
})
setupEnv().then(() =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={graphQlClient}>
          <ChakraProvider theme={extendedTheme}>
            <BrowserRouter>
              <UserProvider>
                <MunicipalityProvider>
                  <SchoolProvider>
                    <App />
                  </SchoolProvider>
                </MunicipalityProvider>
              </UserProvider>
            </BrowserRouter>
          </ChakraProvider>
        </ApolloProvider>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null)
