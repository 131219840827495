import { toPairs } from "lodash-es"

export const roleArr = [
  'Grundskola.SuperAdmin',
  'Grundskola.MunicipalityAdmin',
  'Grundskola.MunicipalityUser',
  'Grundskola.SchoolAdmin',
  'Grundskola.SchoolUser',
]

export const roles = {
  SuperAdmin: 'Grundskola.SuperAdmin',
  MunicipalityAdmin: 'Grundskola.MunicipalityAdmin',
  MunicipalityUser: 'Grundskola.MunicipalityUser',
  SchoolAdmin: 'Grundskola.SchoolAdmin',
  SchoolUser: 'Grundskola.SchoolUser',
}

export const roleLabels = {
  [roles.SuperAdmin]: 'Superadmin',
  [roles.MunicipalityAdmin]: 'Kommunadmin',
  [roles.MunicipalityUser]: 'Kommunanvändare',
  [roles.SchoolAdmin]: 'Skoladmin',
  [roles.SchoolUser]: 'Skolanvändare',
}
export const roleLabelsEn = {
  [roles.SuperAdmin]: 'Super Admin',
  [roles.MunicipalityAdmin]: 'Municipal Admin',
  [roles.MunicipalityUser]: 'Municipal Users',
  [roles.SchoolAdmin]: 'School Admin',
  [roles.SchoolUser]: 'School Users',
}
export const roleOptions = toPairs(roleLabels).map(([value, label]) => ({
  value,
  label,
}))
export const roleOptionsEn = toPairs(roleLabelsEn).map(([value, label]) => ({
  value,
  label,
}))